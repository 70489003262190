<template>
<div>
  <b-card
    title="基本信息"
  >
      <b-row>
        <b-col cols="6">
          <b-form-group
          label="事件ID"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput" v-model="row.id"
          />
        </b-form-group>
        <b-form-group
          label="设备ID"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput" v-model="row.deviceId"
          />
        </b-form-group>
<!--        <b-form-group
          label="关联机构ID"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput" v-model="row.tenantId"
          />
        </b-form-group>-->
        <b-form-group
          label="标签"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput" v-model="row.tagSnapshot"
          />
        </b-form-group>
        <b-form-group
          label="事件时间"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput" v-model="row.triggerTime"
          />
        </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
          label="事件名称"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput" :value="eventTypeFormat(row.eventType)"
          />
        </b-form-group>
        <b-form-group
          label="产品名称"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput" v-model="row.productName"
          />
        </b-form-group>
<!--        <b-form-group
          label="关联机构"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput" v-model="row.tenantNameSnapshot"
          />
        </b-form-group>-->
        <b-form-group
          label="分组"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput" v-model="row.tenantGroupSnapshot"
          />
        </b-form-group>
<!--        <b-form-group
          label="地址"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput" v-model="row.addressSnapshot"
          />
        </b-form-group>-->
        </b-col>
      </b-row>
    </b-card>
    <div>
      <b-button
        class="w-15"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        to="/event/recordList"
      >
        返回
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BCard,BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import request from "@/api/request";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormTextarea
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: this.$route.query.id,
      row: [],
      eventTypeOptions: [],
      eventLvValue: 3,
    }
  },

  created() {
    this.eventTypeOptionsFormat();
    this.getEventInfo();
  },

  watch: {
    '$route.query.id': {
      handler(newVal, oldVal) {
        this.id = newVal
        this.getEventInfo()
      },
    },
    deep: true,
  },

  methods:{
    //事件类型format
    eventTypeFormat(value) {
      if (value != undefined) {
        for (let item of this.eventTypeOptions) {
          if (item.value === value) {
            return item.label;
          }
        }
      }
      return value;
    },

    getEventInfo() {
      request.get('tob/bEventRecord/getEventRecordInfo', {
        id: this.id,
      })
          .then(res => {
            if (res.data.success) {
              this.row = res.data.data
              console.log(this.row)
            } else {
              that.$refs.loginForm.setErrors(res.data.msg)
            }
          })
    },

    //格式化后端返回的事件类型枚举
    eventTypeOptionsFormat() {
      request.get('tob/bEventRecord/getEnumsByEventLv', {
        eventLvValue: this.eventLvValue,
      }).then(res => {
        if (res.data.success) {
          var enums = res.data.data;
          this.eventTypeOptions = [];
          for (const key in enums) {
            var value = enums[key];
            var enumObject = {
              value: key - 0,
              label: value,
            }

            this.eventTypeOptions.push(enumObject);
          }
        }
      })
    },

  }
}
</script>
